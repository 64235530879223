import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - L'exosphère" />
    <h3 className='underline-title'>L'exosphère</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Si je m'asseyais et me levais<br />
        <span className="par-starting"></span>et si du plus haut des escaliers tu ne pénétrais mes pensées <br />
        Si je marchais ou me reposais <br />
        <span className="par-starting"></span>et si tu ne me percevais <br />
        Si sur tous mes chemins de la vie<br />
        <span className="par-starting"></span>et si tu n'en étais familier <br />
        Si avant qu'un mot ne parvînt à mes lèvres<br />
        <span className="par-starting"></span>et si tu ne le savais<br />
      </p>
      <p>
        Alors <br />
        Je me saisirais de ma carabine <br />
        Je foudroyerais Onassis et prendrais son opulence <br />
        A toi je payerais mon chemin avec des drachmes arrachées<br />
        Je verserais des dollars pour tant des messes<br />
        <span className="par-starting"></span>pour que leurs ames soient libérées<br />
        Je m'acheterais alors une mitre et une crosse épiscopale<br />
        <span className="par-starting"></span>afin que j'entre sans frapper dans ta maison <br />
      </p>
      <p>
        Je me fierais sempiternellement à mon Zaïre libératoire<br />
        <span className="par-starting"></span>à mon dollar gendarme<br />
        <span className="par-starting"></span>à mon franc rhétoriqueur<br />
      </p>
      <p>
        Si donc je me faisais d'illusion à cause du tohu-bohu des ténébrions<br />
        Dommage enfin pour moi!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
